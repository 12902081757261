import React from 'react'

import Social from './social'

import styles from './footer.module.scss'

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <Social />
      <div className={styles.copyright}>
        copyright &copy; 2018 Blue Bamboo Studios Limited
      </div>
    </div>
  </footer>
)

export default Footer
