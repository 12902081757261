import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import styles from './header.module.scss'
import helpers from '../styles/helpers.module.scss'

import Logo from '../images/logo.svg'
import LogoText from '../images/logo-text-white.svg'

const Header = () => (
  <header className={styles.header}>
    <div className={styles.logo}>
      <img src={Logo} alt="Blue Bamboo Studios Logo" className={styles.icon} />
      <img src={LogoText} alt="Blue Bamboo Studios" className={styles.text} />
    </div>

    <AnchorLink className={helpers.button} href={`#contact`}>
      Work with us
    </AnchorLink>
  </header>
)

export default Header
