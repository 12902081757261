import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styles from './social.module.scss'

import TwitterIcon from '../images/social-twitter-md.svg'
import FacebookIcon from '../images/social-facebook-md.svg'

const Social = () => (
  <StaticQuery
    query={graphql`
      query SocialLinksQuery {
        site {
          siteMetadata {
            socialLinks {
              twitter
              facebook
            }
          }
        }
      }
    `}
    render={data => (
      <ul className={styles.social}>
        <li>Find us on social media:</li>
        <li>
          <a href={data.site.siteMetadata.socialLinks.twitter}>
            <img src={TwitterIcon} alt="Follow us on Twitter" />
          </a>
        </li>
        <li>
          <a href={data.site.siteMetadata.socialLinks.facebook}>
            <img src={FacebookIcon} alt="Like us on Facebook" />
          </a>
        </li>
      </ul>
    )}
  />
)

export default Social
