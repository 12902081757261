import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

import './layout.module.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          defaultTitle={`${
            data.site.siteMetadata.title
          } - App development in Hampshire`}
          titleTemplate={'%s | ' + data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
            { name: 'robots', content: 'index, nofollow' },
            { name: 'author', content: 'Blue Bamboo Studios limited' },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@bluebambooltd' },
            { name: 'twitter:title', content: 'Blue Bamboo Studios Limited' },
            {
              name: 'twitter:image',
              content: data.site.siteMetadata.siteUrl + '/og.jpg',
            },
            {
              name: 'twitter:description',
              content:
                'Blue Bamboo Studios is an independent app development studio based in hampshire',
            },
            { name: 'og:type', content: 'website' },
            { name: 'og:title', content: 'Blue Bamboo Studios Limited' },
            { name: 'og:site_name', content: 'Blue Bamboo Studios Limited' },
            {
              name: 'og:description',
              content:
                'Blue Bamboo Studios is an independent app development studio based in Hampshire',
            },
            { name: 'og:url', content: data.site.siteMetadata.siteUrl },
            {
              name: 'og:image',
              content: data.site.siteMetadata.siteUrl + '/og.jpg',
            },
          ]}
        >
          <html lang="en" prefix="og: http://ogp.me/ns#" />
        </Helmet>
        <Header />

        {children}

        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
